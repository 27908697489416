import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        trainualDynamicHtmlBox: {
            padding: "0px 20px 20px 20px",
            overflowX: "scroll",
            minHeight: '29vh',
            "& p": {
                color: theme.palette.info.main,
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Montserrat-Regular !important",
                fontSize: "18px !important",
                fontWeight: "300 !important",
                lineHeight: "32px !important",
                textAlign: "justify"
            },
            "& span": {
                color: theme.palette.info.main,
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Montserrat-Regular !important",
                fontSize: "18px !important",
                fontWeight: "300 !important",
                lineHeight: "32px !important",
            },
            "& h2": {
                color: theme.palette.secondary.main,
                fontSize: "32px",
                fontFamily: "Oswald-Regular",
            },
            "& li": {
                color: theme.palette.info.main,
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "Montserrat-Regular !important",
                fontSize: "18px !important",
                fontWeight: "300 !important",
                lineHeight: "32px !important",
            },
            "& table": {
                fontFamily: "Montserrat-Regular",
                borderCollapse: "collapse",
                fontSize: "18px",
                color: theme.palette.info.main,
                width: "100%",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
            },
            "& td": {
                borderBottom: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                fontFamily: "Montserrat-Regular",
            },
            "& tr": {
                borderBottom: "1px solid #dddddd",
                textAlign: "left",
                padding: "8px",
                fontFamily: "Montserrat-Regular",
                color: '#333',
            },
            "& th": {
                borderBottom: "1px solid #404246",
                textAlign: "left",
                padding: "8px",
                color: '#333',
                fontFamily: "Montserrat-Regular",
            },
            "& tr:nth-child(even)": {
                backgroundColor: "#fff",
            },
            "& blockquote": {
                background: "#f1f9fe",
                borderLeft: `10px solid ${theme.palette.primary.main}`,
                margin: "1.5em 10px",
                padding: "0.5em 10px",
                // quotes: "\201C""\201D""\2018""\2019",
            },
            "& blockquote:before": {
                color: theme.palette.common.white,
                // content: "open-quote",
                fontSize: "4em",
                lineHeight: "0.1em",
                marginRight: "0.25em",
                verticalAlign: "-0.4em",
            },
            "& blockquote p": {
                // display: "inline"
            },
        },
    }
}
);

export default styles;