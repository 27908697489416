import React, { useEffect, useState } from 'react';
import styles from "./styles";
import { Box, Button, Tab, Tabs, useMediaQuery } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb/index'
import Header from '../../components/Header/Index';
import Footer from '../../components/Footer';
import TrainingDetailPage from './TrainingDetailPage';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

export default function TrainingDetail() {
    const { classes } = styles();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const isMobile = useMediaQuery("(max-width:768px)");
    const trainingContent = useSelector((state) => state.trainingDetail.trainingDetailItem);
    const topicTitle = trainingContent?.topic_title;
    const subjectTitle = trainingContent?.subjectTitle;

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePreviousTab = () => {
        if (value > 0) setValue((prev) => prev - 1);
    };

    const handleNextTab = () => {
        if (trainingContent && value < trainingContent.topic_contents.length - 1) {
            setValue((prev) => prev + 1);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [value]);

    return (
        <>
            <Header trainualPage={true} position={"relative"} top="0px" />
            <Box className={classes.trainualContentContainer}>
                <div className={classes.breadcrumbBox}><Breadcrumb subjectTitle={subjectTitle} topicTitle={topicTitle} /></div>
                <Box className={isMobile ? classes.mobileTrainualContentTabsBox : classes.trainualContentTabsBox}>
                    <Tabs
                        orientation={isMobile ? "horizontal" : "vertical"}
                        variant="scrollable"
                        value={value}
                        onChange={handleTabChange}
                        className={isMobile ? classes.mobileLeftNavigationPanelBox : classes.leftNavigationPanelBox}
                    >
                        {trainingContent?.topic_contents?.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.content_title.replace(/&amp;/gi, '&')}
                                icon={<FormatListNumberedOutlinedIcon />}
                                iconPosition="start"
                                sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                            />
                        ))}
                    </Tabs>
                    <Box className={classes.rightTrainualContentBox}>
                        {trainingContent?.topic_contents?.[value] ? (
                            <>
                                <TrainingDetailPage content={trainingContent.topic_contents[value].content_description} />
                                <Box className={classes.navigationButtonsBox}
                                    sx={{
                                        justifyContent: value === 0 ? 'flex-end'
                                            : value === trainingContent.topic_contents.length - 1 ? 'flex-start' : 'space-between'
                                    }}
                                >
                                    {value > 0 && < Button
                                        variant="contained"
                                        onClick={handlePreviousTab}
                                        sx={{ minWidth: isMobile ? '130px' : '150px' }}
                                    >
                                        Previous Step
                                    </Button>}
                                    {value < trainingContent.topic_contents.length - 1 && <Button
                                        variant="contained"
                                        onClick={handleNextTab}
                                        sx={{ minWidth: isMobile ? '130px' : '150px' }}
                                    >
                                        Next Step
                                    </Button>}
                                </Box>
                            </>
                        ) : (
                            <h3 style={{ color: theme.palette.secondary.main }}> Content not available</h3>
                        )}
                    </Box>
                </Box>
            </Box >
            <Footer id="footer-detail" />
        </>
    );
};
