import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import megaMenuReducer from "./reducers/megaMenu/megaMenuSlice";
import appSlice from "./reducers/graph";
import storage from "redux-persist/lib/storage/session";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import detailPageSlice from "./reducers/detailPage/detailPageSlice";
import trainualSlice from "./reducers/Trainual/trainualSlice";
import siteAlertsSlice from "./reducers/siteAlerts/siteAlertsSlice";
import userDataSlice from "./reducers/userData/userDataSlice";
import feedbackSlice from "./reducers/Feedback/feedbackSlice";
import feedbackPostSlice from "./reducers/Feedback/feedbackPostSlice";
import oAuthTokenSlice from "./reducers/oAuthToken/oAuthTokenSlice";
import getFavoritesSlice from "./reducers/graph/favorites";
import getFavoritesContentsSlice from "./reducers/graph/favoritesContents"
import searchSlice from "./reducers/Search/searchSlice";
import searchFilterSlice from "./reducers/Search/searchFilterSlice";
import autocompleteSlice from "./reducers/Autocomplete/autocompleteSlice";
import noticeSlice from "./reducers/notice/noticeSlice";
import pollsSlice from "./reducers/polls/pollsSlice"
import pollsPostSlice from "./reducers/polls/pollsPostSlice"
import trainingListSlice from "./reducers/TrainingList/trainingListSlice";
import trainingDetailSlice from "./reducers/TrainingDetail/trainingDetailSlice";

const persistConfig = {
  key: "root",
  whitelist: ["auth", "trainingList", "trainingDetail"],
  storage,
};

const combinedReducer = combineReducers({
  megaMenu: megaMenuReducer,
  apps: appSlice,
  notice: noticeSlice,
  detail: detailPageSlice,
  trainual: trainualSlice,
  trainingList: trainingListSlice,
  trainingDetail: trainingDetailSlice,
  siteAlerts: siteAlertsSlice,
  userData: userDataSlice,
  getFavorites: getFavoritesSlice,
  getFavoritesContents: getFavoritesContentsSlice,
  favorites: getFavoritesSlice,
  feedback: feedbackSlice,
  feedbackPost: feedbackPostSlice,
  oAuthToken: oAuthTokenSlice,
  search: searchSlice,
  searchFilter: searchFilterSlice,
  autoComplete: autocompleteSlice,
  polls: pollsSlice,
  pollsPost: pollsPostSlice,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  //middleware: [thunk]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([]),
});

export const persistor = persistStore(store);
