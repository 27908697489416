import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import styles from "./styles";
import "./style.scss";
import Box from "@mui/material/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import CustomAccordion from "./../../components/Accordion";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Header from "./../../components/Header/Index";
import Footer from "./../../components/Footer";
import { useNavigate, useLocation, Link } from "react-router-dom";
import GroupedAutocomplete from "./../../components/Autocomplete";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../../redux/actions/Search/search";
import {
  addSelectedValue,
  initializeCheckboxStates,
  removeSelectedValue,
  toggleCheckboxState,
  clearSelectedValues,
} from "../../redux/reducers/Search/searchFilterSlice";
import SearchPagination from "./SearchPagination";
import { getAutoComplete } from "../../redux/actions/Autocomplete/autocomplete";
import { fetchAndStoreToken } from "../../redux/actions/oAuthToken/oAuthToken";
import Loader from "../../components/Loader";

export default function SearchResult({
  searchDialogState,
  setSearchDialogState,
}) {
  const theme = useTheme();
  const { classes } = styles();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [queryCategory, setQueryCategory] = useState([]);
  const [filterOpen, setFilterOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [srhKey, setSrhKey] = useState("");
  const { search, loading } = useSelector((state) => state.search);
  const fetched = useSelector((state) => state.search.fetched);
  const { oAuthToken } = useSelector((state) => state.oAuthToken);
  const { autoComplete } = useSelector((state) => state.autoComplete);
  const [options, setOptions] = useState([]);
  const [updateUrl, setUpdateUrl] = useState(false);
  const selectedValues = useSelector(
    (state) => state.searchFilter.selectedValues
  );
  const checkboxStates = useSelector(
    (state) => state.searchFilter.checkboxStates
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize checkbox states from Redux state
    dispatch(initializeCheckboxStates({}));
    dispatch(clearSelectedValues());
  }, []);

  useEffect(() => {
    if (oAuthToken === null || oAuthToken === undefined) {
      dispatch(fetchAndStoreToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (search.total_records > 0) setOptions([]);
  }, [search.search_results]);

  useEffect(() => {
    updateQueryString();
  }, [selectedValues, page]);

  useEffect(() => {
    if (oAuthToken && srhKey.length > 0) {
      setOptions([]);
      if (
        queryCategory === "" ||
        queryCategory === undefined ||
        queryCategory === null ||
        queryCategory.length === 0
      ) {
        dispatch(
          getSearch({
            searchQuery,
            page: page,
            oAuthToken: localStorage.getItem("oAuthToken"),
          })
        );
      } else {
        const queryStringArray = splitQueryCategory(queryCategory);
        const queryString = queryStringFromArray(queryStringArray);
        dispatch(
          getSearch({
            searchQuery,
            queryString,
            page: page,
            oAuthToken: localStorage.getItem("oAuthToken"),
          })
        );
      }
    }
  }, [srhKey, queryCategory, oAuthToken]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("srh_key");
    const searchQueryCategory = searchParams.get("category");
    if (query) {
      setSrhKey(query);
      setSearchQuery(query);
      setQueryCategory(searchQueryCategory);
      dispatch(initializeCheckboxStates({}));
      dispatch(clearSelectedValues());
    }
  }, [location.search]);

  useEffect(() => {
    if (autoComplete && autoComplete.length > 0) {
      // Grouping autoComplete array by categories while maintaining the original order
      const groupedAutoComplete = autoComplete.reduce((acc, item) => {
        const category = item.categories.toUpperCase();
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {});

      // Flattening the grouped results into an array while preserving order
      const sortedAutoComplete = Object.keys(groupedAutoComplete).reduce(
        (acc, category) => {
          return acc.concat(groupedAutoComplete[category]);
        },
        []
      );

      // Mapping sortedAutoComplete to options
      setOptions(
        sortedAutoComplete.map((option) => ({
          firstLetter: /[0-9]/.test(option.categories.toUpperCase())
            ? "0-9"
            : option.categories.toUpperCase(),
          ...option,
        }))
      );
    } else {
      setOptions([]);
    }
  }, [autoComplete]);

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSearch = () => {
    navigate(`/SearchResult?srh_key=${searchQuery}&category=`);
    setQueryCategory([]);
    setPage(0);
    dispatch(clearSelectedValues());
    dispatch(initializeCheckboxStates({}));
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (oAuthToken && searchQuery.length > 0) {
        await setOptions([]);
        handleSearch();
      }
    }
  };

  const handleBlur = () => {
    setOptions([]);
  };
  const handleFocus = (event) => {
    if (event.target.value < 3) setOptions([]);
  };

  const handleSearchClick = async () => {
    if (oAuthToken && searchQuery.length > 0) {
      await dispatch(
        getSearch({
          searchQuery,
          page: 0,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
      await setOptions([]);
      handleSearch();
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length >= 3 && oAuthToken) {
      dispatch(
        getAutoComplete({
          searchQuery: e.target.value,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    } else {
      setOptions([]);
    }
  };
  function handleCheckboxChange(event, typeObj, key, url_alias) {
    setUpdateUrl(true);
    setPage(0);
    const { checked } = event.target;
    const value = url_alias
      ? { [url_alias]: typeObj.raw_value }
      : typeObj.raw_value;

    if (checked) {
      // Add the value to the selectedValues array when checkbox is checked
      dispatch(addSelectedValue(value));
    } else {
      // Remove the value from the selectedValues array when checkbox is unchecked
      if (typeof value === "string") {
        dispatch(removeSelectedValue(value));
        const filterKeys = [];
        search.facets &&
          search.facets?.length > 0 &&
          search?.facets?.map((innerArray, index) => {
            if (
              innerArray.length > 0 &&
              !innerArray[0].hasOwnProperty("type") &&
              Array.isArray(innerArray[0][Object.keys(innerArray[0])[0]])
            ) {
              filterKeys.push(Object.keys(innerArray[0])[0]);
            }
          });
        filterKeys &&
          selectedValues &&
          selectedValues.map((value) => {
            if (
              typeof value === "object" &&
              !filterKeys.includes(Object.keys(value)[0])
            ) {
              dispatch(removeSelectedValue(value));
            }
          });
      } else {
        dispatch(removeSelectedValue(value));
      }
    }
    dispatch(toggleCheckboxState(value));
    // Update the query string based on the selectedValues array
  }
  function updateQueryString() {
    const newUrlAfterFilter = new URL(window.location);
    let queryStringCategory = [];
    selectedValues &&
      selectedValues.map((value) => {
        queryStringCategory.push(value);
      });
    // Construct the query string category parameter
    const queryStringCategoryParam = queryStringCategory
      .map((value) => {
        if (typeof value === "object" && value !== null) {
          return `${Object.keys(value)[0]}:${value[Object.keys(value)[0]]}`;
        }
        return value;
      })
      .join(",");

    const queryString =
      selectedValues &&
      selectedValues
        .map((value, index) =>
          typeof value === "object" && value !== null
            ? `key[${index}]=${Object.keys(value)[0]}:${
                value[Object.keys(value)[0]]
              }`
            : `key[${index}]=categories:${value}`
        )
        .join("&");
    if (updateUrl)
      newUrlAfterFilter.searchParams.set("category", queryStringCategoryParam);
    window.history.pushState({}, "", newUrlAfterFilter);
    if (searchQuery.length > 0 && queryString.length > 0) {
      setOptions([]);
      dispatch(
        getSearch({
          searchQuery,
          queryString,
          page,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    } else if (searchQuery.length > 0 && queryCategory.length === 0) {
      dispatch(
        getSearch({
          searchQuery,
          page,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    } else if (searchQuery.length > 0 && queryCategory.length > 0) {
      const queryStringArray = splitQueryCategory(queryCategory);
      const queryString = queryStringFromArray(queryStringArray);
      dispatch(
        getSearch({
          searchQuery,
          queryString,
          page,
          oAuthToken: localStorage.getItem("oAuthToken"),
        })
      );
    }
  }

  function splitQueryCategory(queryCategory) {
    // Split the string by comma
    const parts = queryCategory.split(",");
    // Process each part
    const queryCategoryArray = parts.map((part) => {
      // If the part contains a colon, it's a key-value pair
      if (part.includes(":")) {
        const [key, value] = part.split(":");
        // Convert to an object
        return { [key.trim()]: parseInt(value.trim(), 10) };
      }
      // Otherwise, it's a simple string
      return part.trim();
    });
    return queryCategoryArray;
  }

  function queryStringFromArray(queryCategoryObj) {
    const queryString =
      queryCategoryObj &&
      Array.isArray(queryCategoryObj) &&
      queryCategoryObj.length > 0 &&
      queryCategoryObj
        .map((value, index) =>
          typeof value === "object" && value !== null
            ? `key[${index}]=${Object.keys(value)[0]}:${
                value[Object.keys(value)[0]]
              }`
            : `key[${index}]=categories:${value}`
        )
        .join("&");
    return queryString;
  }

  const handleUpdateUrl = (bool) => {
    setUpdateUrl(bool);
  };

  return (
    <>
      <Header
        position={"relative"}
        top="0px"
        setSearchDialogState={setSearchDialogState}
        handleUpdateUrl={handleUpdateUrl}
      />
      <Box
        sx={{
          pt: theme.spacing(2),
          pl: theme.spacing(5),
          pb: theme.spacing(5),
          pr: theme.spacing(5),
        }}
        minHeight="calc(100vh - 170px)"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            paddingTop: "inherit",
          }}
        >
          {/* <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Search</InputLabel>
            <Input
              className={classes.searchInput}
              startAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}><SearchIcon /></IconButton>
                  <IconButton><MicIcon /></IconButton>
                </InputAdornment>}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </FormControl> */}
          <Link to="/" className={classes.back}>
            <ArrowBackIcon /> Back{" "}
          </Link>
          {!searchDialogState && (
            <GroupedAutocomplete
              options={options}
              renderGroup={(params) => params.key}
              renderOption={(option) => option.value}
              onChange={(e) => handleInputChange(e)}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              handleBlur={handleBlur}
              handleFocus={handleFocus}
              value={searchQuery}
            />
          )}
        </Box>
        <Box
          className={classes.result}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div onClick={handleFilterOpen} style={{ cursor: "pointer" }}>
            <IconButton aria-label="delete">
              <FilterListIcon />
            </IconButton>
            <span className={classes.filterTxt}>Filter</span>
          </div>

          <Box className={classes.resultPanel}>
            <div
              style={{
                display: filterOpen ? "block" : "none",
                [theme.breakpoints.up("md")]: {
                  width: filterOpen ? "25%" : "0%",
                },
                [theme.breakpoints.down("xs")]: {
                  width: filterOpen ? "100%" : "0%",
                },
              }}
            >
              <Collapse orientation="horizontal" in={filterOpen}>
                {fetched &&
                  search.total_records > 0 &&
                  Object.keys(search)?.length > 0 &&
                  search.facets &&
                  search.facets?.length > 0 && (
                    <CustomAccordion title="Categories">
                      {search?.facets?.flatMap((facetGroup) =>
                        facetGroup?.flatMap((facet) =>
                          facet.type?.map((typeObj) => {
                            return (
                              <div>
                                <FormGroup key={typeObj.raw_value}>
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                      typeObj.values.count
                                        ? typeObj.values.value +
                                          " " +
                                          "(" +
                                          typeObj.values.count +
                                          ")"
                                        : typeObj.values.value
                                    }
                                    onChange={(event) =>
                                      handleCheckboxChange(event, typeObj)
                                    }
                                    checked={checkboxStates[typeObj.raw_value]}
                                  />
                                </FormGroup>
                              </div>
                            );
                          })
                        )
                      )}
                    </CustomAccordion>
                  )}
                {search &&
                  Object.keys(search)?.length > 0 &&
                  search.facets &&
                  search.facets?.length > 0 &&
                  search?.facets?.map((innerArray, index) => {
                    // Check if the inner array is not empty and not of type "type"
                    if (
                      innerArray.length > 0 &&
                      !innerArray[0].hasOwnProperty("type")
                    ) {
                      // Get the key of the non-empty array
                      const key = Object.keys(innerArray[0])[0];
                      let title = "";
                      let url_alias = "";
                      for (const objKey in search.facets_metadata) {
                        if (
                          search.facets_metadata &&
                          search.facets_metadata.hasOwnProperty(objKey)
                        ) {
                          const value = search.facets_metadata[objKey];
                          if (value.field_id === key) {
                            title = value.label;
                            url_alias = value.url_alias;
                          }
                        }
                      }

                      if (Array.isArray(innerArray[0][key])) {
                        return (
                          <CustomAccordion title={title} key={index}>
                            <div>
                              {innerArray[0][key].map((item, itemIndex) => (
                                <FormGroup key={item.raw_value}>
                                  <FormControlLabel
                                    control={<Checkbox />}
                                    label={
                                      item.values.count
                                        ? item.values.value +
                                          " " +
                                          "(" +
                                          item.values.count +
                                          ")"
                                        : item.values.value
                                    }
                                    onChange={(event) => {
                                      handleCheckboxChange(
                                        event,
                                        item,
                                        key,
                                        url_alias
                                      );
                                      // setKey(key);
                                    }}
                                    checked={checkboxStates[item.values.value]}
                                  />
                                </FormGroup>
                              ))}
                            </div>
                          </CustomAccordion>
                        );
                      }
                    }
                    return null;
                  })}
              </Collapse>
            </div>

            {fetched && search.total_records > 0 && (
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{
                  backgroundColor: theme.palette.common.outline,
                  display: filterOpen ? "block" : "none",
                }}
              />
            )}

            <div
              style={{
                width: filterOpen ? "75%" : "100%",
                // padding: theme.spacing(0),
                // [theme.breakpoints.up('md')]: {
                //   width: filterOpen ? '70%' : '100%',
                // },
                [theme.breakpoints.down("xs")]: {
                  width: filterOpen ? "100%" : "100%",
                },
              }}
            >
              {/* { search && Object.keys(search)?.length > 0 && search?.total_records ? (
                <div className={classes.resulttxt}>
                  Found {search?.total_records} results for
                  <div style={{ color: theme.palette.primary.main }}>
                    "{srhKey}"
                  </div>
                </div>
              ) : (
                search && Object.keys(search)?.length > 0 && (
                  <div className={classes.resulttxt}>
                    No result found for
                    <div style={{ color: theme.palette.primary.main }}>
                      "{srhKey}"
                    </div>
                  </div>
                )
              )} */}
              {loading ? (
                <Loader />
              ) : search && fetched ? (
                search.total_records > 0 ? (
                  <>
                    <div className={classes.resulttxt}>
                      Found {search.total_records} results for
                      <div style={{ color: theme.palette.primary.main }}>
                        "{srhKey}"
                      </div>
                    </div>
                    {search.search_results &&
                      search.search_results.map((search_result, index) => (
                        <Link
                          key={index}
                          to={
                            search_result.type === "Applications"
                              ? search_result.app_link_url
                              : search_result.detail_page_api_link
                          }
                          state={search_result}
                          style={{ textDecoration: "none" }}
                          target={
                            search_result.type === "Applications"
                              ? "_blank"
                              : "_self"
                          }
                          rel={
                            search_result.type === "Applications"
                              ? "noopener noreferrer"
                              : ""
                          }
                        >
                          <Box
                            className={classes.resultArticle}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                            }}
                          >
                            <div className={classes.resultArticleHeading}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: search_result.title,
                                }}
                              ></span>
                              <div className={classes.resultArticleInfo}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: search_result.department + " ",
                                  }}
                                ></span>{" "}
                                |
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: " " + search_result.type + " ",
                                  }}
                                ></span>{" "}
                                |
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: " " + search_result.created_on,
                                  }}
                                ></span>
                              </div>
                              <div
                                className={classes.resultArticleTxt}
                                dangerouslySetInnerHTML={{
                                  __html: search_result.body,
                                }}
                              ></div>
                            </div>
                          </Box>
                        </Link>
                      ))}
                  </>
                ) : (
                  <div className={classes.resulttxt}>
                    No result found for
                    <div style={{ color: theme.palette.primary.main }}>
                      "{srhKey}"
                    </div>
                  </div>
                )
              ) : null}
              {search &&
                Object.keys(search)?.length > 0 &&
                search.search_results && (
                  <SearchPagination
                    totalResults={search.total_records}
                    resultsPerPage={20}
                    page={page}
                    setPage={setPage}
                  />
                )}
            </div>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
