import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        trainualRootCard: {
            display: 'flex', height: '140px', boxShadow: 'none', border: 'none'
        },
        trainualCardTitle: {
            color: theme.palette.info.dark,
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Oswald-Regular !important",
            fontSize: "16px !important",
            fontWeight: "700 !important",
            lineHeight: "24px !important",
        },
        trainualCardImg: {
            borderRadius: '75px', width: '40%', objectFit: 'cover', height: "140px"
        },
        trainualCardDescription: {
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Montserrat-Regular !important",
            fontSize: "12px !important",
            fontWeight: "300 !important",
            lineHeight: "16px !important",
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
}
);


export default styles;