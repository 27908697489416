import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        breadcrumbBox: {
            paddingLeft: '20px'
        },
        trainualContentContainer: {
            minHeight: '71vh',
            padding: "20px",
        },
        trainualContentTabsBox: {
            display: 'flex',
            minHeight: '58vh',
            marginTop: '20px'
        },
        mobileTrainualContentTabsBox: {
            display: 'block',
            minHeight: '58vh',
            marginTop: '20px'
        },
        leftNavigationPanelBox: {
            borderRight: '1px solid',
            borderColor: 'rgba(0,0,0,0.12)',
            width: '330px'
        },
        mobileLeftNavigationPanelBox: {
            borderBottom: '1px solid',
            borderColor: 'rgba(0,0,0,0.12)',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center'
        },
        rightTrainualContentBox: {
            width: '75%',
            padding: '0px 24px 24px 24px'
        },
        navigationButtonsBox: {
            display: 'flex', marginTop: '16px'
        }
    }
}
);

export default styles;