import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        trainualListItem: {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            margin: '10px 0',
            '&:hover': {
                backgroundColor: '#f1f9fe',
            },
        },
        trainualListContainer: {
            padding: "30px 100px 50px 100px",
            minHeight: '65vh'
        },
        trainualListHeaderText: {
            color: '#0C3752',
            fontFamily: 'Oswald-Regular',
            fontSize: '32px',
            fontWeight: '600',
            lineHeight: '38px'
        },
        trainualListHeaderBox: {
            marginBottom: '30px'
        },
        trainualListDescription: {
            marginTop: '24px'
        }
    }
}
);


export default styles;